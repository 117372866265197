<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> / Departments
        </h1>
      </v-flex>
      <v-flex xs12 class="mb-5">
        <v-layout row wrap class="d-flex justify-space-between align-center">
          <v-flex xs8 md6 lg4> </v-flex>
          <v-flex xs4 md4 lg4 class="d-flex justify-end">
            <v-btn class="white--text primary" @click="addDepartment" rounded
              ><v-icon class="mr-2">mdi-plus</v-icon>Add Department</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase grey--text">#</th>
                <th class="text-left uppercase grey--text">Role</th>
                <th class="text-left uppercase grey--text">Under</th>
                <th class="text-left uppercase grey--text">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in roles" :key="item._id">
                <td>{{ index + 1 }}</td>
                <td class="uppercase fw-bold">{{ item.name }}</td>
                <td>
                  <span v-if="item.under" class="accent--text uppercase">{{
                    item.under.name
                  }}</span>
                </td>
                <td>
                  <div style="min-width: 120px;">
                    <v-btn
                      small
                      class="white--text uppercase"
                      @click="viewDepartment(item._id)"
                      ><v-icon color="primary">mdi-magnify</v-icon></v-btn
                    >
                    <v-btn
                      small
                      class="white--text ml-5"
                      @click="editDepartment(item._id)"
                      ><v-icon color="primary">mdi-pencil</v-icon></v-btn
                    >
                    <v-btn
                      small
                      class="white--text ml-5"
                      @click="deleteDepartment(item._id)"
                      ><v-icon color="primary">mdi-delete</v-icon></v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import Axios from "axios";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      roles: [],
    };
  },
  mounted() {
    this.fetchDepartments();
  },

  methods: {
    async fetchDepartments() {
      let url = BASE_URL + "/department";
      let { data } = await Axios.get(url);
      this.roles = data;
    },
    viewDepartment(id) {
      this.$router.push({ name: "ViewDepartment", params: { id: id } });
    },
    editDepartment(id) {
      this.$router.push({ name: "EditDepartment", params: { id: id } });
    },
    addDepartment() {
      this.$router.push("/add-department");
    },
    deleteDepartment(id) {
      var context = this;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          confirmButtonColor: "#E09145",
          cancelButtonColor: "#333",
          customClass: {
            popup: "dark-mode-popup",
            header: "dark-mode-header",
            title: "dark-mode-title",
            content: "dark-mode-content",
            confirmButton: "primary",
            cancelButton: "dark-mode-cancel-button",
          },
        })
        .then(async (result) => {
          if (result.value) {
            let url = BASE_URL + "/department/" + id;
            let { data } = await Axios.delete(url);
            this.fetchDepartments();
            this.$swal.fire("Deleted!", "Department deleted.", "success");
          }
        });
    },
  },
};
</script>

<style>
/* Define your dark mode styles */
.dark-mode-popup {
  background-color: #212121 !important;
  color: #fff;
}

.dark-mode-header {
  background-color: #555;
}

.dark-mode-title {
  color: #fff;
}

.dark-mode-content {
  color: #ddd;
}

.dark-mode-confirm-button {
  background-color: #3085d6;
  color: #fff;
}

.dark-mode-cancel-button {
  background-color: #d33;
  color: #fff;
}
</style>
  
  
  